import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import H1 from "../components/h1"
import ContentPage from "../layouts/contentPage"
import ContentPageWrapper from "../layouts/contentPageWrapper"
import styled from "styled-components";

const PolicyWrapper = styled.div`
    font-size: 14px;
    line-height: 20px;
    h1{
      font-size: 40px;
      text-align:center;
      margin-bottom: 100px;
    }
    p,li{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 1rem;
        padding-left: 10px;
        margin-left: -10px;
    }
    ul,ol{
      margin: 0;
      padding: 0;
    }
    li>ul,
    li>ol{
      margin-top: 10px;
      padding-left:24px;
      &[type='disc']{
        padding-left: 0;
        margin-left: 23px;
      }
    }
    h2{
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 40px;
      margin-top: 40px;
    }
    ul[type="disc"]{
      margin-left: -4px;
      padding-left: 0;
      &>li{
        margin-left: -2px;
        padding-left: 5px;
      }
    }
    &>ol{
      padding: 0 15px;
    }
    &>ol>li{
      padding-left: 10px;
      margin-left: 0px;
    }
    #cookies{
      scroll-margin-top : 200px;
    }
`

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Polityka prywatności" />
    <ContentPage>
      <ContentPageWrapper variant={'centered'}>
        <PolicyWrapper>
          <H1 className="h1--contact">POLITYKA PRYWATNOŚCI</H1>
          <h2>Polityka Prywatności i Polityka Cookies</h2>
          <p>Zachowanie poufności danych jest dla nas niezwykle ważne i chcemy, aby każdy nasz Użytkownik wiedział, w jaki sposób je przetwarzamy. Dlatego sporządziliśmy Politykę prywatności, która opisuje sposób ochrony i przetwarzania danych osobowych, a także sposoby i cele gromadzenia plików cookies.</p>
          <p>Administratorem danych osobowych Użytkownika jest firma Aflofarm Farmacja Polska </p>
          <p>sp. z o. o. z siedzibą w Pabianicach (95-200) przy ul. Partyzanckiej 133/151, wpisana do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Łodzi Śródmieścia, XX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000014115 posiadającą kapitał w wysokości 26 271 500 PLN, NIP: 731-18-21-205, REGON: 472871255; zwany też dalej &bdquo;Aflofarm&rdquo;.</p>
          <p>Administrator wyznaczył Inspektora Ochrony Danych.</p>
          <p>W sprawach ochrony swoich danych osobowych i realizacji swoich praw może się Pani/Pan skontaktować z Administratorem za pośrednictwem naszego Inspektora Ochrony wysyłając wiadomość mailową na adres e-mail: <a data-fr-linked="true" href="mailto:daneosobowe@aflofarm.pl">daneosobowe@aflofarm.pl</a></p>
          <ol>
              <li>Definicje:
                  <ul type={'disc'}>
                      <li>Serwis &ndash; strona internetowa pod adresem: <a data-fr-linked="true" href="https://oxygenetic.pl/ ">https://oxygenetic.pl/</a></li>
                      <li>Serwis zewnętrzny &ndash; serwisy internetowe partnerów, usługodawców lub usługobiorców Administratora;</li>
                      <li>Użytkownik &ndash; osoba fizyczna lub osoba prawna, dla której Administrator świadczy usługi drogą elektroniczną za pośrednictwem Serwisu lub udziela odpowiedzi na zapytania skierowane do niego za pośrednictwem formularza dostępnego na stronie Serwisu;  </li>
                      <li>Administrator serwisu internetowego &ndash; Aflofarm Farmacja Polska Sp. z o. o. z siedzibą w Pabianicach (95-200) przy ul. Partyzanckiej 133/151, wpisana do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Łodzi Śródmieścia, XX Wydział Krajowego Rejestru Sądowego pod numerem KRS 0000014115 posiadającą kapitał w wysokości 26 271 500 PLN, NIP: 731-18-21-205, REGON: 472871255; zwany też dalej &bdquo;Aflofarm&rdquo;.</li>
                      <li>Urządzenie &ndash; elektroniczne urządzenie wraz z oprogramowaniem, za pośrednictwem, którego Użytkownik uzyskuje dostęp do Serwisu;</li>
                  </ul>
              </li>
              <li>Niniejsza Polityka prywatności określa zasady gromadzenia, przetwarzania i wykorzystywania danych, w tym również danych osobowych, uzyskanych poprzez stronę Serwisu przez Aflofarm Farmacja Polska Sp. z o. o., który jest Administratorem Danych Osobowych.</li>
              <li>Polityka Cookies określa zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach Użytkowników korzystających z Serwisu do celów świadczenia usług drogą elektroniczną przez Administratora Serwisu. Nadto Polityka Cookies służy także wskazaniu okresu funkcjonowania tych plików oraz określeniu, czy osoby trzecie mogą uzyskać dostęp do takich plików, co zawarte zostało w niniejszej Polityce cookies.</li>
              <li>Dane osobowe są przetwarzane przez Aflofarm zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, tzw. &bdquo;RODO&rdquo;).</li>
              <li>Aflofarm za pomocą strony Serwisu może gromadzić następujące dane dotyczące Użytkownika:
              <ol type="a">
                  <li>informacje dotyczące korzystania przez Użytkownika ze strony Serwisu, o tym kiedy, z jakiego adresu IP i w jaki sposób Użytkownik korzysta ze strony Serwisu;</li>
                  <li>informacje techniczne o urządzeniach, z których korzysta Użytkownik, żeby uzyskać dostęp do Serwisu oraz inne informacje techniczne;</li>
                  <li>rejestracji sesji Użytkownika w Serwisie, w tym dane kliknięć w określone elementy, ruch wskaźnika myszy i dane dotyczące scrollowania strony.</li>
                  <li>wszelkie inne dane, które Użytkownik przekazuje za pośrednictwem formularza kontaktowego, jeżeli został umieszczony na stronie Serwisu lub poczty elektronicznej.</li>
              </ol>
              </li>
              <li>Pozyskane i przetwarzane przez Administratora dane osobowe mogą być przetwarzane jedynie w celu:
                  <ol type="a">
                      <li>udzielenia odpowiedzi na pytanie/zgłoszenie dotyczące produktów, współpracy, działalności Aflofarm &ndash; podstawą prawną przetwarzania jest tutaj zgoda Użytkownika lub prawnie uzasadniony interes Administratora &ndash; art. 6 ust. 1 lit. a i f) RODO, polegający na udzieleniu odpowiedzi na pytanie/zgłoszenie. </li>
                      <li>przyjęcia zgłoszenia działania niepożądanego (podstawą prawną przetwarzania jest ciążący na Aflofarm obowiązek prawny &ndash; art. 6 ust. 1 lit. c) RODO)</li>
                      <li>obsługi zgłoszeń reklamacyjnych &ndash; prawnie uzasadniony interes Administratora &ndash; art. 6 ust. 1 lit. f) RODO polegający na obsłudze zgłoszeń reklamacyjnych, </li>
                      <li>dostarczenia aktualnych informacji o produktach na podstawie udzielonej przez Państwa zgody na dostarczanie treści marketingowych oraz informacji handlowych (art. 6 ust. 1 lit. a RODO oraz art. 172 Prawa Telekomunikacyjnego i art. 10 ust. 2 ustawy o świadczeniu usług drogą elektroniczną),</li>
                      <li>podstawą przetwarzania danych osobowych będzie art. 6 ust. 1 lit. b RODO jeżeli jest to niezbędne do wykonania umowy, której stroną jest Użytkownik lub do podjęcia działań na żądanie Użytkownika, przed zawarciem umowy.</li>
                      <li>korzystania ze strony Serwisu i zapewnienia jej prawidłowego działania- podstawą prawną przetwarzania jest tutaj prawnie uzasadniony interes Administratora &ndash; art. 6 ust. 1 lit. a i f) RODO polegający na prowadzeniu i utrzymaniu strony Serwisu.</li>
                      <li>prowadzeniu statystyk i analizie ruchu w Serwisie celem poprawy jego funkcjonowania i zwiększenia sprzedaży. - podstawą prawną przetwarzania jest tutaj zgoda Użytkownika &ndash; art. 6 ust. 1 lit. a RODO </li>
                  </ol>
                  <p>Podanie danych osobowych jest dobrowolne, ale konieczne dla udzielenia odpowiedzi na pytanie/zgłoszenie dotyczące produktów, współpracy, działalności Aflofarm, przyjęcia zgłoszenia działania niepożądanego lub obsługi zgłoszeń reklamacyjnych. W relacjach umownych podanie danych osobowych stanowi wymóg zawarcia i realizacji umowy. </p>
              </li>
              <li>Jeśli dane osobowe są przetwarzane na podstawie prawnie uzasadnionego interesu, mogą być one przetwarzane i przechowywane pod warunkiem, że Aflofarm ma prawnie uzasadniony interes, a interes ten jest nadrzędny w stosunku do interesów i podstawowych praw Użytkownika. Administrator nie może przetwarzać danych osobowych na podstawie prawnie uzasadnionego interesu, w przypadku wyrażenia skutecznego sprzeciwu w tym zakresie przez osobę, której dane dotyczą. &nbsp; Jeśli uzasadniony prawnie interes Aflofarm dotyczący przetwarzania i przechowywania danych Użytkownika nie jest nadrzędny w stosunku do jego interesów i praw podstawowych, dane nadal mogą być przetwarzane i przechowywane, jeśli jest to wymagane przez prawo. W takich przypadkach dane mogą być przechowywane tylko w celu spełnienia wymagań przepisów prawnych. Okres przechowywania informacji o Użytkowniku zależy od rodzaju uzyskanych informacji. Aflofarm zachowa dane osobowe Użytkownika tylko tak długo, jak będzie to konieczne do osiągnięcia celów określonych w niniejszej Polityce Prywatności i Polityce cookies. Po upływie tego czasu Aflofarm usunie dane osobowe. Jeśli dane osobowe są przetwarzane na podstawie zgody, są one przechowywane do momentu wycofania zgody przez osobę, której dane dotyczą na dalsze przetwarzanie jej danych w tym celu. &nbsp;</li>
              <li>Administrator chroni dane osobowe, stosując odpowiednie techniczne oraz organizacyjne środki bezpieczeństwa w celu uniknięcia przypadkowych bądź umyślnych modyfikacji tych danych, ich utraty, zniszczenia lub uzyskania do nich nieautoryzowanego dostępu.</li>
              <li>Administrator zapewnia przejrzystość przetwarzania danych osobowych. W tym celu informuje o przetwarzaniu danych w momencie ich zbierania, w szczególności o celu i podstawie prawnej przetwarzania danych osobowych, chyba że na podstawie odrębnych przepisów nie jest do tego zobowiązany; dba o to, by dane były zbierane tylko w zakresie niezbędnym do wskazanego celu i przetwarzane były tylko przez okres, w jakim jest to niezbędne.</li>
              <li>Przetwarzając dane osobowe, Administrator zapewnia ich bezpieczeństwo i poufność oraz dostęp do informacji o przetwarzaniu podmiotom, których dane dotyczą. Gdyby, pomimo stosowanych środków bezpieczeństwa, doszło do naruszenia ochrony danych osobowych (np. utraty poufności, dostępności lub integralności) i takie naruszenie mogłoby powodować wysokie ryzyko naruszenia praw lub wolności podmiotów danych, Administrator poinformuje Państwa o takim zdarzeniu w sposób zgodny z obowiązującymi przepisami.</li>
              <li>Administrator podejmuje wszelkie niezbędne działania, by także jego upoważnieni pracownicy, podwykonawcy i inne podmioty współpracujące dawały gwarancję stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy przetwarzają dane osobowe na zlecenie Aflofarm.</li>
              <li>Administrator prowadzi na bieżąco analizę ryzyka i monitoruje adekwatność stosowanych zabezpieczeń danych do identyfikowanych zagrożeń. W razie konieczności wdraża dodatkowe środki służące zwiększeniu bezpieczeństwa danych.</li>
              <li>Odbiorcy danych osobowych:
                  <p>Należy pamiętać, że Administrator przekazuje dane osobowe Użytkowników nie w każdym przypadku i nie do wszystkich odbiorców, którzy zostali wskazani w niniejszej Polityce Prywatności. Dane osobowe będą przekazywane do określonych odbiorców tylko i wyłącznie wtedy, gdy jest to konieczne do realizacji określonego celu przetwarzania danych osobowych i tylko w zakresie niezbędnym do jego realizacji.</p>
                  <ol type="a">
                      <li>Odbiorcami danych osobowych Użytkownika będą (1) jedynie upoważnieni pracownicy Aflofarm i upoważnione osoby działające na zlecenie Aflofarm, a także (2) podmioty działające na zlecenie i w imieniu Aflofarm, tj. podmioty zewnętrzne dostarczające i wspierające systemy teleinformatyczne Aflofarm (ogólną infrastrukturę teleinformatyczną, pocztę elektroniczną, systemy informatyczne stosowane przez Aflofarm), (3) organy nadzorujące przestrzeganie prawa, organy państwowe i inne organy administracji publicznej lub osoby trzecie, jeśli wymagają tego obowiązujące przepisy, (4) biegli rewidenci i inni doradcy profesjonalni.</li>
                      <li>Dane mogą być udostępniane Partnerom Administratora w oparciu o prawnie uzasadniony interes administratora lub na podstawie wyrażonej zgody. Chodzi o udostępnianie Państwa danych osobowych, zapisanych w plikach cookies na Państwa urządzeniach i jego pamięci podręcznej (w tym danych udostępnianych w historii przeglądania i danych zbieranych podczas Państwa aktywności w usługach) oraz danych lokalizacyjnych generowanych przez Państwa urządzenie &ndash; w celach marketingowych (obejmujących zautomatyzowaną analizę Państwa aktywności na stronach internetowych (cookie itp.) na Państwa urządzeniach i odczytywanie takich znaczników. </li>
                      <li>Ponadto, dane osobowe mogą być przekazywane dostawcom wtyczek społecznościowych, skryptów, a także innych zbliżonych narzędzi, które będę powodowały, że przeglądarka osoby, która odwiedza stronę &nbsp;Serwisu pobierze treści od dostawców w/w wtyczek i przekaże w tym celu dane osobowe osoby odwiedzającej, w tym m. in. Administrator korzysta na stronie Serwisu z wtyczek społecznościowych portali społecznościowych: Facebook - w związku z powyższym gromadzi i udostępnia dane osobowe Użytkownika, który korzysta ze strony Serwisu do Facebook Ireland zgodnie z zasadami &nbsp;prywatności określonymi &nbsp;pod adresem: <a data-fr-linked="true" href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a> do Facebook Ireland Ltd.; LinkedIn Ireland Unlimited Company zgodnie z zasadami polityki prywatności dostępnej pod adresem <a data-fr-linked="true" href="https://pl.linkedin.com/legal/privacy-policy, ">https://pl.linkedin.com/legal/privacy-policy,</a>;</li>
                  </ol>
                  <p>Odbiorca: Meta Platforms Ireland Limited, Block J, Serpentine Avenue, Dublin 4, Irlandia</p>
                  <p>Tik Tok zgodnie z zasadami polityki prywatności dostępnej pod adresem: <a data-fr-linked="true" href="https://www.tiktok.com/legal/page/eea/privacy-policy/pl. ">https://www.tiktok.com/legal/page/eea/privacy-policy/pl.</a>; </p>
                  <p>Kontakt: TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Irlandia. Jeśli chcą Państwo uzyskać informacje lub chcą Państwo skorzystać z przysługujących Państwu praw, należy skontaktować się bezpośrednio z odpowiednim operatorem. Wynika to z tego, że tylko odpowiedni operatorzy mają dostęp do Państwa danych osobowych i mogą udzielić Państwu odpowiednich informacji oraz w razie potrzeby podjąć dalsze działania. Jeżeli potrzebują Państwo pomocy w realizacji przysługujących Państwu praw, mogą się Państwo skontaktować z nami w każdej chwili. Opis przetwarzania danych przez odpowiedniego operatora, a także wymagania dotyczące zgłoszenia sprzeciwu (rezygnacji) można znaleźć w informacjach przekazanych przez odpowiedniego operatora dostępnych na stronach internetowych wskazanych powyżej. </p>
              </li>
              <li>W przypadku gdy przetwarzanie danych osobowych odbywa się na podstawie zgody Użytkownika, Użytkownik może w każdym czasie wycofać zgodę na przetwarzanie danych osobowych, przy czym nie wpływa to na wcześniejszą zgodność z prawem przetwarzania danych. Zgoda może zostać cofnięta poprzez złożenie oświadczenia pod adresem e-mail: <a data-fr-linked="true" href="mailto:daneosobowe@aflofarm.pl">daneosobowe@aflofarm.pl</a>.</li>
              <li>Korzystając z Serwisu Użytkownik otrzymuje materiały marketingowe, które są jego integralną częścią. Nieodłącznym elementem Serwisu są np. banery reklamowe, na których Aflofarm może promować swoje usługi i produkty, a także partnerów Administratora bądź inne treści o charakterze reklamowym.</li>
              <li>W każdym czasie Użytkownikowi przysługuje prawo dostępu do treści danych, prawo ich sprostowania, prawo usunięcia danych, prawo do ograniczenia przetwarzania oraz prawo do przenoszenia danych. Oświadczenia w zakresie realizacji praw prosimy składać na adres e-mail: <a data-fr-linked="true" href="mailto:daneosobowe@aflofarm.pl">daneosobowe@aflofarm.pl</a>. Jeżeli mimo naszego wsparcia Użytkownik uzna, że dane osobowe przetwarzane są niezgodnie z przepisami obowiązującego prawa, przysługuje prawo wniesienia skargi do organu nadzorczego (tj. Prezesa Urzędu Ochrony Danych Osobowych). Użytkownikom przysługuje prawo do wniesienia sprzeciwu na przetwarzanie danych osobowych, gdy odbywa się ono na podstawie prawnie uzasadnionego interesu.</li>
              <li>W sprawach nieuregulowanych niniejszą Polityką Prywatności stosuje się odpowiednio właściwe przepisy prawa.</li>
              <li>Administrator Danych Osobowych zastrzega sobie prawo zmiany niniejszej polityki prywatności poprzez opublikowanie nowej wersji w Serwisie.</li>
          </ol>
            <br />
          <h2 id={"cookies"}>POLITYKA COOKIES</h2>
          <ol>
              <li>Co to są pliki cookies?
                  <p>Pliki cookies to małe pliki tekstowe przechowywane na komputerze użytkownika, które pozwalają zapamiętać przepływ informacji między serwisami internetowymi i przeglądarką. Spełnią one szereg funkcji, które mają wpływ na wygodę przeglądania stron internetowych.</p>
              </li>
              <li>Rodzaje Cookies
                  <ul type={'disc'}>
                      <li>Cookies wewnętrzne &ndash; pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez system teleinformatyczny Serwisu;</li>
                      <li>Cookies zewnętrzne &ndash; pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów zewnętrznych;</li>
                      <li>Cookies sesyjne &ndash; pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis podczas jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika;</li>
                      <li>Cookies trwałe &ndash; pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis do momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia, chyba że konfiguracja Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookies po zakończeniu sesji Urządzenia;</li>
                      <li>Cookies niezbędne &ndash; cookies konieczne do prawidłowego działania naszego Serwisu. Obsługują one podstawowe funkcje i służą do zapewnienia bezpieczeństwa. Użycie tych cookies nie wymaga Państwa zgody i są one domyślnie zapisywane na Państwa urządzeniu. Są one aktywne przez czas Pani/Pana wizyty w serwisie lub nieco dłużej. Można zablokować te cookies za pomocą ustawień przeglądarki, ale w takim przypadku Serwis może nie działać prawidłowo. Sprawdź listę cookies niezbędnych.</li>
                      <li>Cookies analityczne &ndash; chcemy analizować, w jaki sposób Pani/Pan jako Użytkownik korzysta z naszego Serwisu. Cookies analityczne nie zbierają informacji, które pozwalają Cię bezpośrednio zidentyfikować. Zbierane informacje dotyczą m.in. Twojego urządzenia, przeglądarki, przybliżonej lokalizacji i tego, w jaki sposób korzystasz z naszego serwisu (np. z jakiej strony odwiedzasz serwis, które strony przeglądasz i jak długo, czy już wcześniej byłeś/-aś w naszym Serwisie), a także danych demograficznych, takich jak wiek, płeć. Dzięki temu możemy tworzyć raporty i statystyki, które pomagają nam ulepszać nasz Serwis. W tym celu korzystamy z narzędzia Google Analytics, a niektóre informacje zebrane przez cookies trafiają do firmy Google; sprawdź listę cookies analitycznych; (więcej poniżej w pkt 8 Polityki cookies);</li>
                      <li>Cookies reklamowe &ndash; chcemy dostarczać Pani/Panu reklamy dopasowane do Państwa zainteresowań oraz mierzyć skuteczność naszych reklam. W tym celu my i nasi partnerzy reklamowi używamy różnych cookies. Niektóre z nich śledzą Pani/Pana aktywność w naszym Serwisie (np. produkty, które u nas przeglądacie), gromadzą różne informacje i przekazują je do naszych partnerów reklamowych. Dzięki temu na innych stronach internetowych mogą Państwo zobaczyć nasze reklamy dopasowane do Państwa zainteresowań (np. jeśli przegląda Pan/Pani nasze produkty, to później może pojawić się ich reklama w innym serwisie). Okres aktywności tych cookies wynosi do 14 miesięcy. </li>
                  </ul>
              </li>
              <li>Bezpieczeństwo
                  <p>Mechanizmy składowania i odczytu &ndash; Mechanizmy składowania i odczytu cookies nie pozwalają na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika. Przeniesienie na Urządzenie Użytkownika wirusów, koni trojańskich oraz innych robaków jest praktycznie niemożliwe.</p>
                  <p>Cookies wewnętrzne &ndash; zastosowane przez Administratora cookies wewnętrzne są bezpieczne dla Urządzeń Użytkowników.</p>
              </li>
              <li>Cele wykorzystywania plików cookies
                  <p>Korzystając z naszej strony internetowej bez zmian w swojej przeglądarce, Użytkownik wyraża zgodę na wykorzystywanie przez nas niezbędnych plików cookies. Dzięki temu nasz Serwis może być całkowicie bezpieczny i wygodny. Ponieważ szanujemy prawo Użytkownika do prywatności, Użytkownik może zrezygnować z akceptowania niektórych rodzajów plików cookies, dokonując wyboru za pośrednictwem udostępnionego w Serwisie banneru. </p>
                  <p>Usprawnienie i ułatwienie dostępu do Serwisu &ndash; Administrator może przechowywać w plikach Cookies informacje o preferencjach i ustawieniach Użytkownika dotyczących Serwisu, aby usprawnić, polepszyć i przyśpieszyć świadczenie usług w ramach Serwisu.</p>
                  <p>PROFILOWANIE: Pani/Pana dane osobowe będą profilowane i dopasowywane do Pana/Pani zainteresowań, które ustalimy na bazie przeglądanych treści w Serwisie należącym do Administratora oraz dokonamy analiz statystycznych w celu udoskonalenia usług. Administrator może korzystać z profilowania </p>
                  <p>Dane statystyczne &ndash; Administrator oraz Serwisy zewnętrzne wykorzystują pliki cookies do zbierania i przetwarzania danych statystycznych takich jak, np. statystyki odwiedzin, statystyki urządzeń Użytkowników czy statystyki zachowań Użytkowników, a także w odniesieniu do danych demograficznych dotyczących wieku i płci. Dane te zbierane są w celu analizy i ulepszania Serwisu.</p>
                  <p>Cookies reklamowe - Za pomocą plików cookies stosowanych w Serwisie możliwe jest zapoznawanie się z preferencjami Użytkownika w tym poprzez analizę tego jak często Użytkownik odwiedza Serwis, czy jakie produkty najczęściej ogląda, jakiego rodzaju artykuły czyta. Analiza zachowań w Internecie pomaga Administratorowi rozumieć zwyczaje i oczekiwania Użytkowników oraz dostosować się do ich potrzeb i zainteresowań. W ten sposób Administrator ma możliwość prezentowania reklam dopasowanych do Użytkownika (przykładowo, reklama wynikająca z faktu przeglądania w ostatnim okresie tylko artykułów dotyczących zawału serca) oraz spośród dostępnych materiałów i artykułów przedstawiać głównie takie, które najlepiej będą odpowiadały potrzebom Użytkownika. </p>
                  <p>Jeśli Użytkownik zrezygnuje z plików cookies pozwalających na wyświetlanie reklam dopasowanych do swoich zainteresowań nie oznacza to, że nie będzie otrzymywać żadnych reklam podczas korzystania z Serwisów. W takim przypadku Użytkownik będzie otrzymywał reklamy, ale bez związku z dotychczasową aktywnością Użytkownika w Serwisie.</p>
                  <p>Serwowanie usług multimedialnych &ndash; Administrator oraz Serwisy zewnętrzne wykorzystują pliki cookies do serwowania Użytkownikom usług multimedialnych.</p>
              </li>
              <li>Serwisy zewnętrzne
                  <p>Korzystając z Serwisu, Użytkownik może otrzymać Pliki cookies pochodzące od współpracujących z Administratorem podmiotów trzecich. Bardziej szczegółowe informacje znajdują się poniżej: </p>
                  <ul type={'disc'}>
                      <li>
                          Dostawcy usług analitycznych - W celu lepszego zrozumienia, jak działa Serwis, Administrator współpracuje z dostawcami usług analitycznych wskazanymi w pkt 8.
                          <p>Więcej informacji na temat tego, jak wykorzystują oni dane Użytkowników, znajdziesz na ich stronach docelowych. </p>
                          <p>Lista partnerów usług analitycznych wskazana w pkt 8. </p>
                      </li>
                      <li>Partnerzy reklamowi- Partnerzy reklamowi Administratora mogą używać cookies reklamowych, by wyświetlać reklamy, które ich zdaniem będą najbardziej interesujące dla Użytkownika, oraz by mierzyć efektywności takich reklam w Serwisie. &nbsp;Nasi partnerzy reklamowi stosują swoje cookies i wykorzystują je zgodnie z własnymi politykami prywatności (np. żeby dopasować reklamy i wyświetlić ją na obsługiwanych przez tych partnerów stronach). Cookies reklamowe nie zbierają informacji, które służą do tego, żeby Panią/Pana bezpośrednio zidentyfikować. Zbierane informacje dotyczą np. lokalizacji; tego, czy Pani/Pan kliknęła/-ął w naszą reklamę i czy skończyło się to zakupem. Listę partnerów reklamowych można znaleźć w pkt 8. </li>
                  </ul>
              </li>
              <li>Możliwości określania warunków przechowywania i uzyskiwania dostępu na Urządzeniach Użytkownika przez Serwis
                  <p>Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia dotyczące zapisywania, usuwania oraz dostępu do danych zapisanych plików Cookies, w tym wycofać zgodę (nie będzie to jednak mieć wpływu na legalność działań podjętych w czasie, gdy zgoda obowiązywała).</p>
                  <p>Informacje o sposobie wyłączenia plików Cookies w najpopularniejszych przeglądarkach komputerowych i urządzeń mobilnych dostępne są na stronach:</p>
                  <p>Firefox: <a data-fr-linked="true" href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek ">https://support.mozilla.org/pl/kb/blokowanie-ciasteczek</a>;</p>
                  <p>Chrome: <a data-fr-linked="true" href="https://support.google.com/chrome/answer/95647?hl=pl&co=GENIE.Platform%3DDesktop#zippy= ">https://support.google.com/chrome/answer/95647?hl=pl&amp;co=GENIE.Platform%3DDesktop#zippy=</a>;</p>
                  <p>Internet Explorer: <a data-fr-linked="true" href="https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d ">https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d</a>;</p>
                  <p>Opera: <a data-fr-linked="true" href="https://help.opera.com/pl/touch/settings/ ">https://help.opera.com/pl/touch/settings/</a>;</p>
                  <p>Safari: <a data-fr-linked="true" href="https://support.apple.com/pl-pl/guide/safari/ibrw850f6c51/mac ">https://support.apple.com/pl-pl/guide/safari/ibrw850f6c51/mac</a>;</p>
                  <p>Ze względu na mnogość rozwiązań technologicznych nie jest możliwe zamieszczenie precyzyjnych wytycznych jak określić warunki przechowywania lub uzyskiwania dostępu do plików Cookies za pomocą ustawień wszystkich dostępnych telekomunikacyjnych urządzeń końcowych i oprogramowania zainstalowanego w tym urządzeniu. Niemniej jednak w większości przypadków należy wybrać opcję &bdquo;Narzędzia&rdquo; lub &bdquo;Ustawienia&rdquo; i tam odszukać sekcję odpowiadającą za konfigurację ustawień plików cookies lub za zarządzanie prywatnością podczas przeglądania Internetu. Szczegółowe informacje zwykle dostarcza producent danego urządzenia lub przeglądarki w instrukcji użytkowania lub na swojej stronie internetowej.</p>
                  <p>Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej pory pliki Cookies korzystając z narzędzi Urządzenia Użytkownika, za pośrednictwem którego Użytkownik korzysta z usług Serwisu.</p>
              </li>
              <li>Wymagania Serwisu
                  <p>Ograniczenie zapisu i dostępu do plików cookies na Urządzeniu Użytkownika może spowodować nieprawidłowe działanie niektórych funkcji Serwisu.</p>
                  <p>Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w przypadku, gdy Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania i odczytu plików Cookies.</p>
              </li>
              <li>Serwisy zewnętrzne
                  <p>W ramach naszych ofert online korzystamy z Google Analytics. Google Analytics to narzędzie dostarczane przez firmę Google LLC z USA. Google przetwarza informacje dla nas, na podstawie Pani/Pana zgody. Cookies analityczne nie zbierają informacji, które pozwalają Cię bezpośrednio zidentyfikować. Zbierane informacje dotyczą np. Twojego urządzenia, przeglądarki, przybliżonej lokalizacji i tego, w jaki sposób korzystasz z naszego serwisu (np. z jakiej strony odwiedzasz serwis, które strony przeglądasz i jak długo, czy już wcześniej byłeś/-aś w naszym Serwisie), a także danych demograficznych, takich jak wiek, płeć. Dla dodatkowej ochrony prywatności włączyliśmy anonimizację adresów IP.</p>
                  <ol>
                      <li>
                          Dostawca: Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland
                          <p>Polityka prywatności: <a data-fr-linked="true" href="https://policies.google.com/privacy?hl=de ">https://policies.google.com/privacy?hl=de</a>;</p>
                          <p>Warunki: <a data-fr-linked="true" href="https://marketingplatform.google">https://marketingplatform.google</a> com/about/analytics/terms/us/</p>
                          <p>Rezygnacja: <a data-fr-linked="true" href="http://tools.google.com/dlpage/gaoptout?hl=de ">http://tools.google.com/dlpage/gaoptout?hl=de</a>;</p>
                          <p>Ustawienia prywatności: <a data-fr-linked="true" href="https://myaccount.google.com/privacycheckup?utm_source=pp&utm_medium=Promo-in-product&utm_campaign=pp_intro&pli=1 ">https://myaccount.google.com/privacycheckup?utm_source=pp&amp;utm_medium=Promo-in-product&amp;utm_campaign=pp_intro&amp;pli=1</a>;</p>
                          <p>Więcej informacji o Google Analytics: Jeśli wyraziłeś zgodę, nasza strona internetowa może korzystać z Google Analytics 4. Stroną wykonawczą dla użytkowników w UE/EOG i Szwajcarii jest Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irlandia (&bdquo;Google&rdquo;).</p>
                          <p>Zakres przetwarzania: Google Analytics 4 wykorzystuje pliki cookies, które umożliwiają analizę korzystania przez Ciebie z naszych stron internetowych. W Google Analytics 4 anonimizacja adresu IP jest domyślnie włączona. Ze względu na anonimizację adresu IP Twój adres IP zostanie skrócony przez Google na terenie państw członkowskich Unii Europejskiej lub w innych państwach będących stronami Porozumienia o Europejskim Obszarze Gospodarczym. Tylko w wyjątkowych przypadkach pełny adres IP zostanie przesłany na serwer Google w USA i tam skrócony. Według Google adres IP przekazany przez Pani/Pana przeglądarkę w ramach Google Analytics nie będzie łączony z innymi danymi Google.</p>
                          <p>Odbiorcami danych są/mogą być w szczególności:</p>
                          <ul type={"disc"}>
                              <li>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irlandia (jako podmiot przetwarzający zgodnie z art. 28 RODO)</li>
                              <li>Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA</li>
                              <li>Alphabet Inc, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA</li>
                          </ul>
                          <p>Nie można wykluczyć, że władze USA uzyskają dostęp do danych przechowywanych przez Google.</p>
                          <p>Pliki cookie są ważne przez 14 miesięcy.</p>
                          <p>W ramach platformy Google Analytics Administrator korzysta z narzędzia Consent Mode v2. Consent Mode v2 umożliwia dostosowywanie zbierania danych analitycznych i marketingowych w zależności od zgody, której udziela Użytkownik. Narzędzie to pozwala zbierać pliki cookies w celach analitycznych i marketingowych, tylko wtedy, jeśli użytkownik wyrazi zgodę. Jeśli użytkownik nie wyrazi zgody nie będzie możliwości zbierania danych w tych celach.</p>
                      </li>
                      <li>Google Tag Manager
                          <p>Korzystamy z narzędzia Google Tag Manager zapewnianego przez Google LLC, USA. Menedżer tagów Google może gromadzić pewne dane zbiorcze dotyczące uruchamiania tagów, aby śledzić i udostępniać informacje diagnostyczne o stabilności systemu, wydajności jego działania i jakości instalacji. Dane te nie obejmują adresów IP użytkowników ani żadnych identyfikatorów pomiaru powiązanych z konkretną osobą. Menedżer tagów Google nie zbiera, nie przechowuje ani nie udostępnia żadnych informacji o użytkownikach witryn, w tym odwiedzanych przez nich adresów URL, oraz wspomnianych wyżej informacji diagnostycznych. Wyjątek stanowią dane zapisywane w standardowych dziennikach żądań HTTP, które są w całości usuwane w ciągu 14 dni od otrzymania. </p>
                          <p>Więcej informacji o tym, jak Google Tag Manager korzysta z zebranych danych, znajdziesz w Warunkach korzystania z usługi: </p>
                          <p><a data-fr-linked="true" href="https://support.google.com/tagmanager/answer/7157428?sjid=11585760675700610593-EU ">https://support.google.com/tagmanager/answer/7157428?sjid=11585760675700610593-EU</a>;</p>
                          <p>Polityką prywatności Google:<a data-fr-linked="true" href="https://policies.google.com/privacy?hl=pl ">https://policies.google.com/privacy?hl=pl</a>;</p>
                          <p>Zasady korzystania z Menedżera tagów Google: </p>
                          <p>&nbsp;<a data-fr-linked="true" href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/ ">https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/</a>;</p>
                      </li>
                      <li>Nasza strona internetowa wykorzystuje wtyczki społecznościowe przekierowujące do naszych profili społecznościowych, o których więcej wspomnieliśmy powyżej a także narzędzia analityczne i marketingowe, takie jak:
                        <ol type={'a'}>
                            <li>META (Facebook) Piksel
                                <p>META (Facebook) Piksel to narzędzie analityczne, fragment kodu wdrożony na stronie, pozwalający na kierowanie działań marketingowych do osób, które odwiedziły naszą stronę lub zainteresowane są naszymi działaniami.</p>
                                <p>Zbierane w ramach narzędzia dane są anonimowe (lokalizacja, płeć, wiek, aktywność w Internecie), przy czym dostawca może łączyć je z danymi, jakie zgromadził o Tobie w ramach korzystania przez Ciebie z jego platformy. META (Facebook) Piksel pomaga nam określić skuteczność naszych reklam, dotrzeć do określonej grupy odbiorców i pokazuje ich reakcje na nasze działania.</p>
                                <p>Więcej informacji znajdzie się na stronie internetowej: </p>
                                <p><a data-fr-linked="true" href="https://pl-pl.facebook.com/business/help/742478679120153?id=1205376682832142. ">https://pl-pl.facebook.com/business/help/742478679120153?id=1205376682832142.</a>;</p>
                            </li>
                            <li>Piksel TikTok
                                <p>Piksel TikTok to fragment kodu umożliwiający udostępnianie TikTok zdarzeń w witrynie internetowej, które umieszczamy na swojej stronie internetowej. Za pośrednictwem tego narzędzia możemy mierzyć ruch na naszej stronie internetowej i skuteczność kampanii reklamowych oraz optymalizować kampanie. </p>
                                <p>Szczegółowe informacje dotyczące tego narzędzia znajdują się na stronie internetowej: </p>
                                <p><a data-fr-linked="true" href="https://ads.tiktok.com/help/article/tiktok-pixel?lang=pl-PL ">https://ads.tiktok.com/help/article/tiktok-pixel?lang=pl-PL</a>;</p>
                                <p><a data-fr-linked="true" href="https://ads.tiktok.com/help/article/using-cookies-with-tiktok-pixel?redirected=2 ">https://ads.tiktok.com/help/article/using-cookies-with-tiktok-pixel?redirected=2</a>;</p>
                            </li>
                        </ol>
                      </li>
                      <li>Partnerzy reklamowi
                          <p>Partnerzy reklamowi Administratora mogą używać cookies reklamowych, by wyświetlać reklamy, które ich zdaniem będą najbardziej interesujące dla Użytkownika, oraz by mierzyć efektywności takich reklam w Serwisie. Nasi partnerzy reklamowi stosują swoje cookies i wykorzystują je zgodnie z własnymi politykami prywatności (np. żeby dopasować reklamy i wyświetlić ją na obsługiwanych przez tych partnerów stronach). Cookies reklamowe nie zbierają informacji, które służą do tego, żeby Panią/Pana bezpośrednio zidentyfikować. Zbierane informacje dotyczą np. lokalizacji; tego, czy Pani/Pan kliknęła/-ął w naszą reklamę i czy skończyło się to zakupem.</p>
                          <ol type={'a'}>
                              <li>
                                  Administrator może korzystać w Serwisie z usług CRITEO dostarczanych przez firmę Criteo spółka akcyjna. &nbsp;Usługi te pozwalają promować Aflofarm swoje produkty i usługi, poprzez dostosowywanie kampanii i/lub ofert do odbiorców, do których chcemy dotrzeć z treściami, co z kolei oznacza, że mogą Państwo otrzymywać reklamy, które mogą Was zainteresować. Criteo nie wykorzystuje żadnych danych, które pozwalają bezpośrednio Państwa zidentyfikować, takich jak imię i nazwisko, adres pocztowy, adres e-mail w postaci zwykłego tekstu itp. Kiedy przeglądana jest witryna Aflofarmu korzystająca z usług Criteo, technologia ta pozwala zbierać ograniczoną ilość danych związanych z przeglądaniem stron przez Użytkownika, takich jak produkty, które oglądałeś, które umieściłeś w koszyku i które kupiłeś.
                                  <p>Więcej informacji o rodzaju danych gromadzonych przez Criteo i zasadach gromadzenia oraz przetwarzania danych osobowych znajdują się na stronie internetowej: <a data-fr-linked="true" href="https://www.criteo.com/privacy/ ">https://www.criteo.com/privacy/</a>;</p>
                                  <p>Aby wyłączyć usługi Criteo w przeglądarkach internetowej wystarczy kliknąć przycisk &bdquo;Wyłącz usługi Criteo&rdquo; na stronie internetowej: <a data-fr-linked="true" href="https://www.criteo.com/privacy/disable-criteo-services-on-internet-browsers/ ">https://www.criteo.com/privacy/disable-criteo-services-on-internet-browsers/</a>;</p>
                                  <p>Dostawca: Criteo spółka akcyjna z siedzibą pod adresem 32 rue Blanche, 75009 Paris, FRANCJA</p>
                              </li>
                              <li>Administrator może korzystać w Serwisie z usług Adform dostarczanych przez firmę
                                  <p>Adform A/S Silkegade 3B, ST. &amp; 1., 1113 Copenhagen, Denmark, Tel: +45 3535 7100. Usługi te pozwalają na przeprowadzenie przez lub na rzecz Aflofarm planowania, uruchamiania, wyświetlania oraz optymalizacji reklamy sponsorowanej.</p>
                                  <p>Więcej informacji o rodzaju danych gromadzonych przez Adform i zasadach gromadzenia oraz przetwarzania danych osobowych znajdują się na stronie internetowej: <a data-fr-linked="true" href="https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/ ">https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/</a>;</p>
                              </li>
                          </ol>
                      </li>
                  </ol>
              </li>
              <li>Zmiany w Polityce Prywatności i Polityce Cookies
              <ol>
                  <li>Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Prywatności i Polityki Cookies.</li>
                  <li>Wprowadzone zmiany zawsze będą publikowane na tej stronie.</li>
                  <li>Wprowadzone zmiany wchodzą w życie w dniu publikacji.</li>
              </ol>
              </li>
          </ol>
          <script id="CookieDeclaration" src="https://consent.cookiebot.com/946c2d7b-6f97-449c-bd6e-5e955623fe7b/cd.js" type="text/javascript"></script>
        </PolicyWrapper>
      </ContentPageWrapper>
    </ContentPage> 
  </Layout>
)

export default PrivacyPolicy
